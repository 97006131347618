import { FiMail } from 'react-icons/fi';
import { Image, Card, Col, Container, Row } from 'react-bootstrap';
import AnimatedCursor from 'react-animated-cursor';
import { Helmet } from 'react-helmet';
import { toast, Toaster } from 'sonner';
import { isMobile } from 'react-device-detect';
import Particle from '../Particle';
import RRSSC from './RRSSCard';
import RRSSC2 from './RRSSCard2';

const socialLinks = [
  { 
    id: 1, 
    titulo: 'Twitter ~ 𝕏', 
    enlace: 'https://www.x.com/ImPavloh', 
    imagen: 'https://i.imgur.com/Ix6BvUB.png', 
    impresiones: 280000000, 
    seguidores: 5300
  },
  { 
    id: 2, 
    titulo: 'YouTube', 
    enlace: 'https://www.youtube.com/c/@Pavloh', 
    imagen: 'https://i.imgur.com/q9253nZ.png',
    visualizaciones: 400000 , 
    suscriptores: 2000
  },
  { 
    id: 3, 
    titulo: 'TikTok', 
    enlace: 'https://www.tiktok.com/@impavloh', 
    imagen: 'https://i.imgur.com/9vP30aX.png', 
    visitas: 400000, 
    seguidores: 350
  }
];

const socialLinks2 = [
  { 
    id: 1, 
    titulo: 'Discord', 
    enlace: 'http://www.discord.com/users/471780464542351393',
    imagen: 'https://i.imgur.com/7XVmaCw.png'
  },
  { 
    id: 2, 
    titulo: 'GitHub', 
    enlace: 'https://www.github.com/ImPavloh', 
    imagen: 'https://i.imgur.com/tfLVGGB.png'
  },
  { 
    id: 3, 
    titulo: 'Instagram', 
    enlace: 'https://www.instagram.com/ImPavloh', 
    imagen: 'https://i.imgur.com/M7glSjl.png'
  }
];

const pm = "impavloh@gmail.com"

async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Correo copiado');
  } catch (err) {
    toast.error('Hubo un problema al copiar', err);
  }
}

function RRSS() {
  return (
    <Container fluid className="page-container">
      {!isMobile && <AnimatedCursor innerSize={8} trailingSpeed={3} outerSize={20} outerAlpha={0} innerScale={0.7} outerScale={2} innerStyle={{ boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)', backgroundColor: '#faedcd'}} outerStyle={{boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)', border: '3px solid #fefaf0'}} clickables={['input[type="text"]','input[type="email"]','input[type="number"]','input[type="submit"]','input[type="image"]','label[for]','select','textarea','button','.link','a','span','.FM']}/>}
      <Helmet>
        <title>Pavloh | Conexiones</title>
        <meta property="og:title" content="Redes Sociales" />
        <meta property="og:description" content="Redes sociales de Pavloh" />
        <meta property="og:url" content="https://www.pavloh.com/conexiones" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Redes Sociales" />
        <meta name="twitter:description" content="Redes sociales de Pavloh" />
      </Helmet>
      <Particle />
      <Toaster position="top-center" richColors  />
      <Container className="hc2 justifyNews">
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col style={{ justifyContent: "center", paddingBottom: "15px"}}>
            <Card className="quote-card-view">
              <Card.Body style={{ textAlign: "center" }}>
                <h3 style={{ textAlign: "center", marginBottom: "20px" }}>¡Puedes encontrarme a través de varias plataformas! </h3>
                <FiMail className="FM" style={{ marginRight: "10px"}} onClick={() => { copyToClipboard(pm);}}/>
                <span class="copy-text" onClick={() => { copyToClipboard(pm); }}>{pm}</span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justifyNews">
          {socialLinks.map((link) => ( <RRSSC key={link.id} {...link} /> ))}
          {socialLinks2.map((link) => ( <RRSSC2 key={link.id} {...link} /> ))}
        </Row>
        <div className="imgsSocial">
          <a href="https://t.me/impavloh" target="_blank" rel="noopener noreferrer">
            <Image className="imgSocial" src="https://img.shields.io/badge/Telegram-2CA5E0?style=for-the-badge&logo=telegram&logoColor=white" alt="Telegram" />
          </a>
          <a href="https://www.linkedin.com/in/impavloh" target="_blank" rel="noopener noreferrer">
            <Image className="imgSocial" src="https://img.shields.io/badge/LinkedIn-0077B5?style=for-the-badge&logo=linkedin&logoColor=white" alt="LinkedIn" />
          </a>
        </div>
        {/*<div className="imgsSocial">
          <a href="" download={`CV - .pdf`} target="_self">
            <button variant="primary" className="downloadSocialCV"><AiOutlineDownload className="icon-spacer"/>Descargar CV</button>
          </a>
  </div>*/}
      </Container>
    </Container>
  );
}

export default RRSS;
