import GA from "../../Assets/certificados/googleactivate.webp"
import SL from "../../Assets/certificados/sololearn.webp"
import P from "../../Assets/certificados/platzi.webp"

import CCI from "../../Assets/certificados/googleactivate/Cloud Computing.webp";
import CCPDF from "../../Assets/certificados/googleactivate/Cloud Computing.pdf";

import CEI from "../../Assets/certificados/googleactivate/Comercio electrónico.webp";
import CEPDF from "../../Assets/certificados/googleactivate/Comercio electrónico.pdf";

import CDPI from "../../Assets/certificados/googleactivate/Competencias digitales para profesionales.webp";
import CDPPDF from "../../Assets/certificados/googleactivate/Competencias digitales para profesionales.pdf";

import CDAI from "../../Assets/certificados/googleactivate/Curso de Desarrollo de Apps Móviles.webp";
import CDAPDF from "../../Assets/certificados/googleactivate/Curso de Desarrollo de Apps Móviles.pdf";

import CIDHCI1 from "../../Assets/certificados/googleactivate/Curso de Introducción al Desarrollo Web HTML y CSS1.webp";
import CIDHCPDF1 from "../../Assets/certificados/googleactivate/Curso de Introducción al Desarrollo Web HTML y CSS1.pdf";

import CIDHCI2 from "../../Assets/certificados/googleactivate/Curso de Introducción al Desarrollo Web HTML y CSS2.webp";
import CIDHCPDF2 from "../../Assets/certificados/googleactivate/Curso de Introducción al Desarrollo Web HTML y CSS2.pdf";

import DNHGI from "../../Assets/certificados/googleactivate/Digitaliza paso a paso tu negocio con herramientas de Google.webp";
import DNHGPDF from "../../Assets/certificados/googleactivate/Digitaliza paso a paso tu negocio con herramientas de Google.pdf";

import PPI from "../../Assets/certificados/googleactivate/Productividad Personal.webp";
import PPPDF from "../../Assets/certificados/googleactivate/Productividad Personal.pdf";

import PNCTI from "../../Assets/certificados/googleactivate/Protege tu Negocio Ciberseguridad en el Teletrabajo.webp";
import PNCTPDF from "../../Assets/certificados/googleactivate/Protege tu Negocio Ciberseguridad en el Teletrabajo.pdf";

import TDEI from "../../Assets/certificados/googleactivate/Transformación digital para el empleo.webp";
import TDEPDF from "../../Assets/certificados/googleactivate/Transformación digital para el empleo.pdf";

import FCCI from "../../Assets/certificados/platzi/FundamentoCloudComputingAWS.webp";
import FCCPDF from "../../Assets/certificados/platzi/FundamentoCloudComputingAWS.pdf";

import LNPI from "../../Assets/certificados/platzi/LenguajeNaturalPytorch.webp";
import LNPPDF from "../../Assets/certificados/platzi/LenguajeNaturalPytorch.pdf";

import IAII from "../../Assets/certificados/platzi/IAIngles.webp";
import IAPDF from "../../Assets/certificados/platzi/IAIngles.pdf";

import RNPTI from "../../Assets/certificados/platzi/RedesNeuronalesPyTorch.webp";
import RNPTPDF from "../../Assets/certificados/platzi/RedesNeuronalesPyTorch.pdf";

import EMDDSI from "../../Assets/certificados/platzi/EticaManejoDatosDataScienceIA.webp";
import EMDDSPDF from "../../Assets/certificados/platzi/EticaManejoDatosDataScienceIA.pdf";

import PEI from "../../Assets/certificados/platzi/PromptEngineeringChatGPT.webp";
import PEPDF from "../../Assets/certificados/platzi/PromptEngineeringChatGPT.pdf";

import HIAI from "../../Assets/certificados/platzi/HerramientasIADevs.webp";
import HIAPDF from "../../Assets/certificados/platzi/HerramientasIADevs.pdf";

import IATI from "../../Assets/certificados/platzi/InteligenciaArtificalTrabajo.webp";
import IATPDF from "../../Assets/certificados/platzi/InteligenciaArtificalTrabajo.pdf";

import IIAI from "../../Assets/certificados/platzi/IntroduccionIA.webp";
import IIAPDF from "../../Assets/certificados/platzi/IntroduccionIA.pdf";

import ECDSI from "../../Assets/certificados/platzi/EmpezarCarreraDataScienceIA.webp";
import ECDSPDF from "../../Assets/certificados/platzi/EmpezarCarreraDataScienceIA.pdf";

import CCSSLI from "../../Assets/certificados/sololearn/Csharpcert-5817558-1080.webp";
import CCSSLPDF from "../../Assets/certificados/sololearn/Csharpcert-5817558-1080.pdf";

import CCPPSLI from "../../Assets/certificados/sololearn/Cpluspluscert-5817558-1051.webp";
import CCPPSLPDF from "../../Assets/certificados/sololearn/Cpluspluscert-5817558-1051.pdf";

import CCSLI from "../../Assets/certificados/sololearn/Ccert-1089-5817558.webp";
import CCSLPDF from "../../Assets/certificados/sololearn/Ccert-1089-5817558.pdf";

import CCSSSLI from "../../Assets/certificados/sololearn/CSScert-1023-5817558.webp";
import CCSSSLPDF from "../../Assets/certificados/sololearn/CSScert-1023-5817558.pdf";

import CHTMLI from "../../Assets/certificados/sololearn/HTMLcert-1014-5817558.webp";
import CHTMLPDF from "../../Assets/certificados/sololearn/HTMLcert-1014-5817558.pdf";

import CJAVAI from "../../Assets/certificados/sololearn/Javacert-5817558-1068.webp";
import CJAVAPDF from "../../Assets/certificados/sololearn/Javacert-5817558-1068.pdf";

import CJI from "../../Assets/certificados/sololearn/JQUERYcert-1082-5817558.webp";
import CJPDF from "../../Assets/certificados/sololearn/JQUERYcert-1082-5817558.pdf";

import CJSI from "../../Assets/certificados/sololearn/JScert-5817558-1024.webp";
import CJSPDF from "../../Assets/certificados/sololearn/JScert-5817558-1024.pdf";

import CPHPI from "../../Assets/certificados/sololearn/PHPcert-1059-5817558.webp";
import CPHPPDF from "../../Assets/certificados/sololearn/PHPcert-1059-5817558.pdf";

import CPYI from "../../Assets/certificados/sololearn/PythonCorecert-5817558-1073.webp";
import CPYPDF from "../../Assets/certificados/sololearn/PythonCorecert-5817558-1073.pdf";

import CRUBYI from "../../Assets/certificados/sololearn/Rubycert-1081-5817558.webp";
import CRUBYPDF from "../../Assets/certificados/sololearn/Rubycert-1081-5817558.pdf";

import CSQLI from "../../Assets/certificados/sololearn/SQLcert-5817558-1060.webp";
import CSQLPDF from "../../Assets/certificados/sololearn/SQLcert-5817558-1060.pdf";

import CS4I from "../../Assets/certificados/sololearn/Swift4cert-1075-5817558.webp";
import CS4PDF from "../../Assets/certificados/sololearn/Swift4cert-1075-5817558.pdf";

const FolderLinks = [{
    id: 1,
    folderName: 'Google Actívate',
    folderImage: GA,
    certificates: [{
        id: 1,
        titulo: 'Cloud Computing',
        imagen: CCI,
        pdf: CCPDF
    }, {
        id: 2,
        titulo: 'Comercio electrónico',
        imagen: CEI,
        pdf: CEPDF
    }, {
        id: 3,
        titulo: 'Competencias digitales para profesionales',
        imagen: CDPI,
        pdf: CDPPDF
    }, {
        id: 4,
        titulo: 'Desarrollo de Apps Móviles',
        imagen: CDAI,
        pdf: CDAPDF
    }, {
        id: 5,
        titulo: 'Desarrollo Web HTML y CSS 1',
        imagen: CIDHCI1,
        pdf: CIDHCPDF1
    }, {
        id: 6,
        titulo: 'Desarrollo Web HTML y CSS2',
        imagen: CIDHCI2,
        pdf: CIDHCPDF2
    }, {
        id: 7,
        titulo: 'Digitalización de negocios con herramientas de Google',
        imagen: DNHGI,
        pdf: DNHGPDF
    }, {
        id: 8,
        titulo: 'Productividad personal',
        imagen: PPI,
        pdf: PPPDF
    }, {
        id: 9,
        titulo: 'Ciberseguridad para negocios en el teletrabajo',
        imagen: PNCTI,
        pdf: PNCTPDF
    }, {
        id: 10,
        titulo: 'Transformación digital para el empleo',
        imagen: TDEI,
        pdf: TDEPDF
    }]
}, {
    id: 2,
    folderName: 'Platzi',
    folderImage: P,
    certificates: [{
        id: 1,
        titulo: 'Fundamentos de AWS Cloud Computing',
        imagen: FCCI,
        pdf: FCCPDF
    }, {
        id: 2,
        titulo: 'Ética en el manejo de datos para Data Science e IA',
        imagen: EMDDSI,
        pdf: EMDDSPDF
    }, {
        id: 3,
        titulo: 'Fundamentos de procesamiento de lenguaje natural con Python',
        imagen: LNPI,
        pdf: LNPPDF
    }, {
        id: 4,
        titulo: 'English course to work with artificial intelligence tools',
        imagen: IAII,
        pdf: IAPDF
    }, {
        id: 5,
        titulo: 'Redes neuronales con PyTorch',
        imagen: RNPTI,
        pdf: RNPTPDF
    }, {
        id: 6,
        titulo: 'ChatGPT Prompt Engineering',
        imagen: PEI,
        pdf: PEPDF
    }, {
        id: 7,
        titulo: 'Herramientas de IA para desarrolladores',
        imagen: HIAI,
        pdf: HIAPDF
    }, {
        id: 8,
        titulo: 'Inteligencia Artifical en el trabajo',
        imagen: IATI,
        pdf: IATPDF
    }, {
        id: 9,
        titulo: 'Introducción a la Inteligencia Artificial',
        imagen: IIAI,
        pdf: IIAPDF
    }, {
        id: 10,
        titulo: 'Inicio de carrera en Data Science e IA',
        imagen: ECDSI,
        pdf: ECDSPDF
    }]
}, {
    id: 3,
    folderName: 'Sololearn',
    folderImage: SL,
    certificates: [{
        id: 1,
        titulo: 'Certificado de C#',
        imagen: CCSSLI,
        pdf: CCSSLPDF
    }, {
        id: 2,
        titulo: 'Certificado de C++',
        imagen: CCPPSLI,
        pdf: CCPPSLPDF
    }, {
        id: 3,
        titulo: 'Certificado de C',
        imagen: CCSLI,
        pdf: CCSLPDF
    }, {
        id: 4,
        titulo: 'Certificado de CSS',
        imagen: CCSSSLI,
        pdf: CCSSSLPDF
    }, {
        id: 5,
        titulo: 'Certificado de HTML',
        imagen: CHTMLI,
        pdf: CHTMLPDF
    }, {
        id: 6,
        titulo: 'Certificado de Java',
        imagen: CJAVAI,
        pdf: CJAVAPDF
    }, {
        id: 7,
        titulo: 'Certificado de jQuery',
        imagen: CJI,
        pdf: CJPDF
    }, {
        id: 8,
        titulo: 'Certificado de JavaScript',
        imagen: CJSI,
        pdf: CJSPDF
    }, {
        id: 9,
        titulo: 'Certificado de PHP',
        imagen: CPHPI,
        pdf: CPHPPDF
    }, {
        id: 10,
        titulo: 'Certificado de Python',
        imagen: CPYI,
        pdf: CPYPDF
    }, {
        id: 11,
        titulo: 'Certificado de Ruby',
        imagen: CRUBYI,
        pdf: CRUBYPDF
    }, {
        id: 12,
        titulo: 'Certificado de SQL',
        imagen: CSQLI,
        pdf: CSQLPDF
    }, {
        id: 13,
        titulo: 'Certificado de Swift4',
        imagen: CS4I,
        pdf: CS4PDF
    }]
}]

export default FolderLinks;