import { useState } from 'react';
import { Container, Image, Modal, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { TiTick } from 'react-icons/ti';
import { RiInformationLine } from 'react-icons/ri';
import { isMobile, isBrowser } from "react-device-detect";

import AnimatedCursor from "react-animated-cursor";
import Particle from "../Particle";

import VMP from "../../Assets/vmpanel.webp";
import VML from "../../Assets/vmlogo.webp";
import VMLang from "../../Assets/vmlanguages.webp";
import SO from "../../Assets/Server_owner.svg";
import DCM from "../../Assets/Discord_certified_moderator.svg";
import DL from "../../Assets/Discord_logo.svg";
import privacidad from "../../Assets/privacidad.pdf";
import condiciones from "../../Assets/condiciones.pdf";

const botInviteLink = "https://voiceme.pavloh.com/invite";

const sections = [
  {
    img: VMP,
    title: "¡Personaliza todo!",
    description: "Elige entre todas la voces, variedad de acentos, tonos e idiomas para personalizar la salida de voz y hacer que suene de la manera que quieras."
  }
];

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    ¡Bot verificado!
  </Tooltip>
);

const Modal2 = ({ show, onHide }) => (
  <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ zIndex: 998 }}>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        VoiceMe! v.1.6
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Para obtener más información, consulta descargando la{' '}
        <a href={privacidad} download="Pavloh - VoiceMe - Política de privacidad.pdf" target="_self">Política de privacidad</a> y{' '}
        <a href={condiciones} download="Pavloh - VoiceMe - Condiciones de uso.pdf" target="_self">Condiciones de uso</a>.
      </p>
    </Modal.Body>
  </Modal>
);

const SupportModal = ({ onClose }) => (
  <div className="modal-background" style={{ animation: "fadeIn 0.5s" }}>
    <div className="modal2">
      <h2 style={{ animation: "fadeIn 0.5s" }}>Apoya este proyecto</h2>
      <p style={{ animation: "fadeIn 1s" }}>Con tu ayuda, podré continuar y mejorar VoiceMe! Actualmente estoy cubriendo los gastos del hosting del bot, la API y otras características por mi cuenta.</p>
      <p style={{ animation: "fadeIn 1s" }}>Toda ayuda, aunque no es necesaria, es muy apreciada :)</p>
      <h5 style={{ animation: "fadeIn 0.5s" }}>Puedes contribuir a través de <strong>PayPal</strong> o <strong>Coffee</strong></h5>
      <h5 style={{ animation: "fadeIn 0.5s" }}>Elige una opción:</h5>
      <div className="donateButtons">
        <form className="donation-button" action="https://www.paypal.com/donate" method="post" target="_blank" rel="noopener noreferrer">
          <input type="hidden" name="hosted_button_id" value="UKNPN6EQFKJAL" />
          <input type="image" src="https://www.paypalobjects.com/es_ES/ES/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="Botón de donar con PayPal" />
          <Image draggable="false" alt="" border="0" src="https://www.paypal.com/es_ES/i/scr/pixel.gif" width="1" height="1" />
        </form>
        <a className="donation-button" href="https://www.buymeacoffee.com/pavloh" target="_blank" rel="noopener noreferrer">
          <Image alt="coffee" src="https://img.buymeacoffee.com/button-api/?text=Apoyar&emoji=&slug=pavloh&button_colour=FFDD00&font_colour=000000&font_family=Poppins&outline_colour=000000&coffee_colour=ffffff" />
        </a>
      </div>
      <button className="close-button" style={{ animation: "fadeIn 2s", width: "175px" }} onClick={onClose}>Cancelar</button>
    </div>
  </div>
);

const Section = ({ img, title, description }) => (
  <section className="section desktop-section mt-sm mb-sm">
    <div className="col-span-1" style={{ position: "relative" }}>
      <Image className="hidee imgSec" src={img} alt={title} />
    </div>
    <div className="col-span-1" style={{ position: "relative" }}>
      <h2 style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", color: "white" }}>{title}</h2>
      <p className="description-text" style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", color: "white" }}>{description}</p>
      <a href={botInviteLink} target="_blank" rel="noopener noreferrer">
        <button className="button-primary">
          <div className="button-content">
            <Image alt="Discord Logo" src={DL} />
            <span className="button-text">Añadir a Discord</span>
          </div>
        </button>
      </a>
      <Image src={VMLang} alt="Languages" className="overlay-image" />
    </div>
  </section>
);

const VoiceMe = () => {
  const [modalShow, setModalShow] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);

  return (
      <Container fluid className="home-section2" id="VoiceMe" >
        {!isMobile && <AnimatedCursor innerSize={8} trailingSpeed={3} outerSize={20} outerAlpha={0} innerScale={0.7} outerScale={2} innerStyle={{ boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)', backgroundColor: '#faedcd'}} outerStyle={{boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)', border: '3px solid #fefaf0'}} clickables={['input[type="hidden"]','input[type="text"]','input[type="email"]','input[type="number"]','input[type="submit"]','input[type="image"]','label[for]','select','textarea','button','.link','a','.button-5']}/>}
        <Helmet>
          <title>Pavloh | VoiceMe!</title>
          <meta property="og:title" content="VoiceMe!" />
          <meta property="og:url" content="https://pavloh.com/voiceme" />
          <meta property="og:title" content="Pavloh | VoiceMe!" />
          <meta property="og:description" content="Texto a voz usando Inteligencia Artifical" />
          <meta name="twitter:title" content="Pavloh | VoiceMe!" />
          <meta name="twitter:description" content="Texto a voz usando Inteligencia Artifical" />
        </Helmet>
        <Particle />
      <div className="section-title">
        <Image draggable="false" src={VML} className="logo2" alt="VoiceMe!" style={{ margin: "25px", borderRadius: "50%", boxShadow: "rgba(200, 160, 255, 0.5) 0px 0px 50px" }} />
        <div className="button-content">
          <h1 style={{ color: "white" }}>VoiceMe!</h1>
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            <Badge className="verifiedBadge" bg="primary"><TiTick style={{ marginBottom: "3px" }} /> BOT</Badge>
          </OverlayTrigger>
        </div>
        <p style={{ color: "white" }}>Texto a voz usando Inteligencia Artifical</p>
        <p style={{ color: "#57f287", fontSize: "0.7em" }}><RiInformationLine style={{ marginBottom: "3px" }} /> Bot en línea en 700 servidores con 20.000 usuarios activos</p>
        <div className="buttons">
          <a href={botInviteLink} target="_blank" rel="noopener noreferrer">
            <button className="button-primary">
              <div className="button-content">
                <Image alt="Discord Logo" src={DL} />
                <span className="button-text">Añadir a Discord</span>
              </div>
            </button>
          </a>
          <button className="button-secondary" onClick={() => setModalShow(true)}>
            <div className="button-content">
              <Image alt="VoiceMe server" src={DCM} />
              <span className="button-text">Más información</span>
            </div>
          </button>
          <Modal2 show={modalShow} onHide={() => setModalShow(false)} />
        </div>
        <button className="button-4" onClick={() => setShowSupportModal(true)}>
          <div className="button-content">
            <Image alt="Support" src={SO} style={{ filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.75))", maxHeight: "24px", marginBottom: "2px" }} />
            <span className="button-text" style={{ paddingRight: "2px" }}>Apoyar</span>
          </div>
        </button>
      </div>
      {sections.map((section, index) => isBrowser && !isMobile && <Section key={index} {...section} />)}
      {showSupportModal && (
        <SupportModal onClose={() => setShowSupportModal(false)} />
      )}
    </Container>
  );
};

export default VoiceMe;