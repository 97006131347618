import Container from "react-bootstrap/Container";
import { PiCertificate } from "react-icons/pi";
import { SiDiscord } from "react-icons/si";
import { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useLocation } from "react-router-dom";
import { AiOutlineBook, AiOutlineHome, AiOutlineFundProjectionScreen, AiOutlineUser, AiOutlineShareAlt } from "react-icons/ai";
import Nav from "react-bootstrap/Nav";
import { isMobile, isBrowser } from "react-device-detect";
import Navbar from "react-bootstrap/Navbar";
import { Image } from "react-bootstrap";
import logo from "../Assets/logo.png";

const NavBar = () => {
  const location = useLocation();
  const navClass = useMemo(() => {
    return location.pathname === "/voiceit" || location.pathname === "/mvl" ? "default-cursor" : "";
  }, [location.pathname]);

  const [expand, setExpand] = useState(false);
  const [navColour, setNavColour] = useState(false);

  const disabledLinkStyle = useMemo(() => ({ color: "darkgrey", pointerEvents: "none" }), []);

  const scrollHandler = useCallback(() => {
    setNavColour(window.scrollY >= 20);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [scrollHandler]); 

  const links = [
    { path: "/", label: "Inicio", icon: <AiOutlineHome /> },
    { path: "/info", label: "Sobre mi", icon: <AiOutlineUser /> },
    { path: "/proyectos", label: "Proyectos", icon: <AiOutlineFundProjectionScreen /> },
    { path: "/certificados", label: "Certificados", icon: <PiCertificate /> },
    { path: "/noticias", label: "Noticias", icon: <AiOutlineBook /> },
    { path: "/conexiones", label: "Conexiones", icon: <AiOutlineShareAlt /> }
  ];

  return (
    <Navbar expanded={expand} fixed="top" expand="md" className={`${navColour ? "sticky" : "navbar"} ${navClass}`} style={{ zIndex: 998, display: "block", textAlign: "center", color: "lightblue"}} aria-label="Main navigation">
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex" style={location.pathname === "/" ? disabledLinkStyle : {}}>
          <Image src={logo} className="img-fluid logo" alt="Pavloh"/>
        </Navbar.Brand>
        {isBrowser && !isMobile && (
          <Nav.Link style={location.pathname === "/voiceme" ? disabledLinkStyle : {}} as={Link} to="/voiceme">
            <SiDiscord style={{ marginBottom: "2px"}}/> <span>VoiceMe!</span>
          </Nav.Link>
        )}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" aria-label="Toggle navigation" onClick={() => setExpand(!expand)}>
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="marg" defaultActiveKey="#home">
            {links.map(link => (
              <Nav.Item key={link.path}>
                <Nav.Link as={Link} to={link.path} onClick={() => setExpand(false)} style={location.pathname === link.path ? disabledLinkStyle : {}}>
                  {link.icon} {link.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;