import NoticiaCard from './NewsCard';
import Particle from '../Particle';
import { Container, Row } from 'react-bootstrap';
import AnimatedCursor from "react-animated-cursor"
import { Helmet } from 'react-helmet';
import { isMobile } from "react-device-detect";

const noticias = [
  {
    id: 1,
    titulo: 'El Universal',
    descripcion: '¿Llegó demasiado lejos? Influencer se hace pasar por Ibai Llanos y Leo Messi con ayuda de la IA',
    enlace: 'https://www.eluniversal.com.mx/tendencias/llego-demasiado-lejos-influencer-bromea-con-ia-que-es-capaz-de-replicar-la-voz-de-famosos/',
    imagen: 'https://i.imgur.com/jOsWt5J.png',
  },
  {
    id: 2,
    titulo: 'Hero Network',
    descripcion: 'El Rubius habla sobre la posibilidad de cantar "Minero" remix con Ibai Llanos en La Velada 4',
    enlace: 'https://www.beahero.gg/el-rubius-habla-sobre-la-posibilidad-de-cantar-minero-remix-con-ibai-llanos-en-la-velada-4/',
    imagen: 'https://i.imgur.com/XGvqLcN.png',
  },
  {
    id: 3,
    titulo: 'MeriStation',
    descripcion: 'Así es la IA que transcribe audios con la voz de Messi o Ibai como IlloJuan en Bellum',
    enlace: 'https://as.com/meristation/betech/asi-es-la-ia-que-transcribe-audios-con-la-voz-de-messi-o-ibai-como-illojuan-en-bellum-n/',
    imagen: 'https://i.imgur.com/qJfpY1b.png',
  },
  {
    id: 4,
    titulo: 'Yahoo! Noticias',
    descripcion: 'Influencer se hace pasar por Ibai Llanos y Messi con ayuda de IA',
    enlace: 'https://es-us.noticias.yahoo.com/influencer-pasar-ibai-llanos-messi-002801434.html',
    imagen: 'https://i.imgur.com/nW7ypMV.png',
  },
  {
    id: 5,
    titulo: 'Movistar eSports',
    descripcion: 'La IA pone a Ibai Llanos a versionar a Rubius y su "Soy Minero"',
    enlace: 'https://esports.as.com/bonus/influencers/IA-Ibai-Llanos-Rubius-Minero_0_1678932093.html',
    imagen: 'https://i.imgur.com/UhBrJoE.png',
  },
  {
    id: 6,
    titulo: 'TeleDiario',
    descripcion: 'Illojuan utiliza Inteligencia Artificial para imitar las voces de Ibai Llanos y Messi',
    enlace: 'https://www.telediario.mx/tendencias/illojuan-se-hace-pasar-por-ibai-llanos-y-messi-con-ia',
    imagen: 'https://i.imgur.com/QMu2gt8.png',
  },
];

function News() {
  return (
    <Container className="page-container">
      {!isMobile && <AnimatedCursor innerSize={8} trailingSpeed={3} outerSize={20} outerAlpha={0} innerScale={0.7} outerScale={2} innerStyle={{ boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)', backgroundColor: '#faedcd'}} outerStyle={{boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)', border: '3px solid #fefaf0'}} clickables={['input[type="text"]','input[type="email"]','input[type="number"]','input[type="submit"]','input[type="image"]','label[for]','select','textarea','button','.link','a']}/>}
        <Helmet>
          <title>Pavloh | Noticias</title>
          <meta property="og:title" content="Noticias" />
          <meta property="og:description" content="Noticias sobre Pavloh" />
          <meta property="og:image" content="https://i.imgur.com/k32Z1K6.png" />
          <meta property="og:url" content="https://www.pavloh.com/news" />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Noticias" />
          <meta name="twitter:description" content="Noticias sobre Pavloh" />
          <meta name="twitter:image" content="https://i.imgur.com/k32Z1K6.png" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Particle />
        <Container className="home-content justifyNews" style={{ position: 'relative', zIndex: 1 }}>
          <h3 style={{ textAlign: "center", marginBottom: "25px" }}>Noticias que han escrito sobre mí y mis proyectos</h3>
          <Row className="justifyNews">
            {noticias.map((noticia) => (
              <NoticiaCard className="justifyNews" key={noticia.id} {...noticia} />
            ))}
          </Row>
        </Container>
      </Container>
  );
}

export default News;