import SkeletonCard from '../SkeletonCard';
import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';

function abbreviateNumber(num) {
  if (num >= 1e9) {
    return +(num / 1e9).toFixed(1) + 'B';
  } 
  if (num >= 1e6) {
    return +(num / 1e6).toFixed(1) + 'M';
  } 
  if (num >= 1e3) {
    return +(num / 1e3).toFixed(1) + 'K';
  }
  return num.toString();
}

const RRSSC = ({ id, titulo, enlace, imagen, seguidores, impresiones, visualizaciones, visitas, suscriptores, isLoading }) => (
  isLoading ? <SkeletonCard /> : (
    <Col md={4}>
      <a href={enlace} target="_blank" rel="noopener noreferrer" className="card-link">
        <Card className="mb-3 card-shadow card-hover-zoom">
          <Card.Img variant="top" src={imagen} alt={titulo} style={{border: '1px solid #ccc', height: '200px', objectFit: 'cover'}} />
          <Card.Body className="card-container">
            <Card.Title className="card-title"><strong>{titulo}</strong></Card.Title>
            {impresiones > 0 && <Card.Text>{abbreviateNumber(impresiones)} impresiones</Card.Text>}
            {visualizaciones > 0 && <Card.Text>{abbreviateNumber(visualizaciones)} visualizaciones</Card.Text>}
            {visitas > 0 && <Card.Text>{abbreviateNumber(visitas)} visitas</Card.Text>}
            {seguidores > 0 && <Card.Text>{abbreviateNumber(seguidores)} seguidores</Card.Text>}
            {suscriptores > 0 && <Card.Text>{abbreviateNumber(suscriptores)} suscriptores</Card.Text>}
          </Card.Body>
        </Card>
      </a>
    </Col>
  )
);

RRSSC.propTypes = {
  id: PropTypes.number.isRequired,
  titulo: PropTypes.string.isRequired,
  enlace: PropTypes.string.isRequired,
  imagen: PropTypes.string.isRequired,
  seguidores: PropTypes.number,
  impresiones: PropTypes.number,
  visualizaciones: PropTypes.number,
  visitas: PropTypes.number,
  suscriptores: PropTypes.number,
  emoji: PropTypes.string,
  isLoading: PropTypes.bool
};

export default RRSSC;