import { Card, Col } from 'react-bootstrap';

const SkeletonCard = () => (
  <Col md={4}>
    <Card className="mb-3 card-shadow card-hover-zoom">
      <Card.Img variant="top" style={{border: '1px solid #ccc', height: '200px', background: '#fff'}} />
      <Card.Body className="card-container">
        <Card.Title className="card-title">Cargando...</Card.Title>
      </Card.Body>
    </Card>
  </Col>
);

export default SkeletonCard;