import SkeletonCard from '../SkeletonCard';
import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';

const NewsCard = ({ id, titulo, descripcion, enlace, imagen, isLoading }) => (
  isLoading ? <SkeletonCard /> : (
  <Col md={4}>
    <a href={enlace} target="_blank" rel="noopener noreferrer" className="card-link2">
      <Card className="mb-3 card-shadow card-hover-zoom">
        <Card.Img variant="top" src={imagen} alt={titulo} style={{border: '1px solid #ccc', height: '200px', objectFit: 'cover'}} />
        <Card.Body className="card-container">
          <Card.Title>{titulo}</Card.Title>
          <Card.Text>{descripcion}</Card.Text>
        </Card.Body>
      </Card>
    </a>
  </Col>
  )
);

NewsCard.propTypes = {
  id: PropTypes.number.isRequired,
  titulo: PropTypes.string.isRequired,
  descripcion: PropTypes.string.isRequired,
  enlace: PropTypes.string.isRequired,
  imagen: PropTypes.string.isRequired,
};

export default NewsCard;