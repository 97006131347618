import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';
import { GrCertificate } from 'react-icons/gr';

import SkeletonCard from '../SkeletonCard';
import CertificadoCarousel from './CertificadoCarousel';

const FolderCard = ({
  folderName,
  folderImage,
  certificates,
  isLoading = false
}) => {
  if (isLoading) {
    return <SkeletonCard />;
  }

  return (
    <Col md={4}>
      <Card className="mb-3 card-shadow card-hover">
        <Card.Img variant="top" src={folderImage} alt={folderName} className="card-image" />
        <Card.Body className="card-container2">
          <Card.Title className="card-title2">
            <GrCertificate className="certificate-icon" /> {folderName} ({certificates.length})
          </Card.Title>
        </Card.Body>
      </Card>
      <Card className="mb-3 card-shadow card-hover">
        <CertificadoCarousel certificates={certificates} />
      </Card>
    </Col>
  );
};

FolderCard.propTypes = {
  folderName: PropTypes.string.isRequired,
  folderImage: PropTypes.string.isRequired,
  certificates: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool
};

export default FolderCard;