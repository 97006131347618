import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Preloader from "./components/Preloader";
import ScrollToTop from './components/ScrollToTop';

import Home from './components/Home/Home';
import Info from './components/About/About';
import Proyectos from './components/Projects/Projects';
import Certificados from './components/Certificados/Certificados';
import Noticias from './components/News/News';
import Conexiones from './components/RRSS/RRSS';
import VoiceIt from './components/VoiceIt/VoiceIt';
import VoiceMe from './components/VoiceMe/VoiceMe';

const LM = lazy(() => import('./components/LM/LM'));

const routes = [
  { path: "/", element: <Home /> },
  { path: "/voiceme", element: <VoiceMe /> },
  { path: "/proyectos", element: <Proyectos /> },
  { path: "/certificados", element: <Certificados /> },
  { path: "/info", element: <Info /> },
  { path: "/noticias", element: <Noticias /> },
  { path: "/conexiones", element: <Conexiones /> },
  { path: "/voiceit", element: <VoiceIt /> },
  { path: "/lm", element: <LM /> },
  { path: "*", element: <Navigate to="/" replace /> },
];

function App() {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const handleLoad = () => setLoad(false);
    const disableDefault = (e) => e.preventDefault();

    document.readyState === 'complete' ? handleLoad() : window.addEventListener('load', handleLoad);
    window.addEventListener('contextmenu', disableDefault);
    window.addEventListener('dragstart', disableDefault);

    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('contextmenu', disableDefault);
      window.removeEventListener('dragstart', disableDefault);
    };
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Preloader load={load} />
      <div className="App">
        <Navbar />
        <ScrollToTop />
        <Suspense fallback={<div>Cargando...</div>}>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;