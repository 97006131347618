import Particle from '../Particle';
import FolderCard from './FolderCard';
import FolderLinks from './Links.js';
import { Container, Row } from 'react-bootstrap';
import AnimatedCursor from 'react-animated-cursor';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

function Certificados() {
  return (
    <Container className="page-container">
      {!isMobile && <AnimatedCursor innerSize={8} trailingSpeed={3} outerSize={20} outerAlpha={0} innerScale={0.7} outerScale={2} innerStyle={{ boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)', backgroundColor: '#faedcd'}} outerStyle={{boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)', border: '3px solid #fefaf0'}} clickables={['input[type="text"]','input[type="email"]','input[type="number"]','input[type="submit"]','input[type="image"]','label[for]','select','textarea','button','.link','a','span','Button']}/>}
      <Helmet>
        <title>Pavloh | Certificados</title>
        <meta property="og:title" content="Certificados" />
        <meta property="og:description" content="Certificados de Pavloh" />
        <meta property="og:url" content="https://www.pavloh.com/certificados" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Certificados" />
        <meta name="twitter:description" content="Certificados de Pavloh" />
      </Helmet>
      <Particle />
      <Container className="hc2 justifyNews">
        <h3 style={{ textAlign: "center", marginBottom: "25px" }}>Mis certificados</h3>
        <Row className="justifyNews">
          {FolderLinks.map(({ id, ...linkProps }) => (
            <FolderCard key={id} {...linkProps} />
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Certificados;