import PropTypes from 'prop-types';
import Card from "react-bootstrap/Card";
import { Carousel } from 'react-responsive-carousel';

const Technologies = ({ technologies }) => (
  <div className="tech">
    {Array.isArray(technologies) &&
      technologies.map((tech, index) => (
        tech.logo && (
          <Card.Img key={index} src={tech.logo} alt={tech.name} style={{ width: "auto", height: "30px", margin: "10px", borderRadius: "5px", boxShadow: "rgba(150, 255, 255, 0.5) 0px 0px 8px"}}/>
        )
      ))
    }
  </div>
);

const Buttons = ({ buttons }) => (
  <div>
    {buttons.map((button, index) => {
      const IconComponent = button.icon;
      return (
        <a key={index} href={button.link} className={`btn btn-${button.variant}`} style={{ marginRight: buttons.length > 1 ? "5px" : "0px" }}>
          <IconComponent style={{ marginBottom: "2px" }} /> &nbsp; {button.text}
        </a>
      );
    })}
  </div>
);

const SingleImage = ({ imgPath, imageStyle, titlePosition, titleMargin, title, descriptionPosition, descriptionMargin, description }) => (
  <div>
    <Card.Img variant="top" src={imgPath} alt="card-img" style={{ width: "100%", height: "auto", objectFit: imageStyle.objectFit }} />
    {titlePosition === "bottom" && <Card.Title style={{ margin: titleMargin || "10px" }}>{title}</Card.Title>}
    {descriptionPosition === "bottom" && <Card.Text style={{ textAlign: "center", margin: descriptionMargin || "10px" }}>{description}</Card.Text>}
  </div>
);

const ImageCarousel = ({ imgPaths, imageStyle, titlePosition, titleMargin, title, descriptionPosition, descriptionMargin, description }) => (
  <div>
    <Carousel dynamicHeight={true} showArrows={true} showStatus={false} showIndicators={true} showThumbs={false} infiniteLoop={true} transitionTime={400} interval={7000} autoPlay={true} emulateTouch={true}>
      {imgPaths.map((imgPath, index) => (
        <div key={index}>
          <Card.Img variant="top" src={imgPath} alt="card-img" style={{ width: "100%", height: "auto", objectFit: imageStyle.objectFit }} />
        </div>
      ))}
    </Carousel>
    {titlePosition === "bottom" && <Card.Title style={{ margin: titleMargin || "10px" }}>{title}</Card.Title>}
    {descriptionPosition === "bottom" && <Card.Text style={{ textAlign: "center", margin: descriptionMargin || "10px" }}>{description}</Card.Text>}
  </div>
);

function ProjectCards(props) {
  const { showLogo, logoPath, logoMargin, titlePosition, titleMargin, title, descriptionPosition, descriptionMargin, description, imgPaths, imageStyle, technologies, buttons } = props;

  return (
    <Card className="project-card-view">
      {showLogo && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Card.Img src={logoPath} alt="logo" style={{ margin: logoMargin || "15px", borderRadius: "50%", width: "80px", height: "80px" }} />
        </div>
      )}
      {titlePosition === "top" && <Card.Title style={{ margin: titleMargin || "10px" }}>{title}</Card.Title>}
      {descriptionPosition === "top" && <Card.Text style={{ textAlign: "center", margin: descriptionMargin || "10px" }}>{description}</Card.Text>}
      
      {imgPaths.length === 1 ? (
        <SingleImage imgPath={imgPaths[0]} imageStyle={imageStyle} titlePosition={titlePosition} titleMargin={titleMargin} title={title} descriptionPosition={descriptionPosition} descriptionMargin={descriptionMargin} description={description}/>
      ) : (
        <ImageCarousel imgPaths={imgPaths} imageStyle={imageStyle} titlePosition={titlePosition} titleMargin={titleMargin} title={title} descriptionPosition={descriptionPosition} descriptionMargin={descriptionMargin} description={description}/>
      )}

      <Card.Body style={{ textAlign: "center" }}>
        <Technologies technologies={technologies}/>
        {buttons.length > 0 && <Buttons buttons={buttons}/>}
      </Card.Body>
    </Card>
  );
}

ProjectCards.propTypes = {
  showLogo: PropTypes.bool,
  logoPath: PropTypes.string,
  logoMargin: PropTypes.string,
  titlePosition: PropTypes.string,
  titleMargin: PropTypes.string,
  title: PropTypes.string,
  descriptionPosition: PropTypes.string,
  descriptionMargin: PropTypes.string,
  description: PropTypes.string,
  imgPaths: PropTypes.arrayOf(PropTypes.string),
  imageStyle: PropTypes.shape({
    objectFit: PropTypes.string
  }),
  technologies: PropTypes.arrayOf(PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string
  })),
  buttons: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.elementType,
    variant: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string
  }))
};

ProjectCards.defaultProps = {
  showLogo: false,
  logoPath: '',
  logoMargin: '15px',
  titlePosition: 'top',
  titleMargin: '10px',
  title: '',
  descriptionPosition: 'top',
  descriptionMargin: '10px',
  description: '',
  imgPaths: [],
  imageStyle: {
    objectFit: 'cover'
  },
  technologies: [],
  buttons: []
};

export default ProjectCards;