import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

function VoiceIt() {
  return (
    <>
      <Helmet>
        <title>Pavloh | VoiceIt!</title>
        <meta property="og:url" content="https://pavloh.com/voiceit" />
        <meta property="og:title" content="Pavloh | VoiceIt!" />
        <meta property="og:description" content="Cambia la voz de audios con IA" />
        <meta name="twitter:title" content="Pavloh | VoiceIt!" />
        <meta name="twitter:description" content="Cambia la voz de audios con IA" />
      </Helmet>
      <Container fluid className="voiceit default-cursor">
        <iframe
          title="VoiceIt!"
          src="https://impavloh-voiceit.hf.space/?__theme=dark"
          className="voiceit-iframe"
          seamless="seamless"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Container>
    </>
  );
}

export default VoiceIt;