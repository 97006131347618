import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CertificadoCard from './CertificadoCard';

const CertificadoCarousel = ({ certificates }) => (
  <Carousel
    className="bb"
    dynamicHeight
    showArrows
    showIndicators
    infiniteLoop
    autoPlay
    emulateTouch
    showStatus={false}
    showThumbs={false}
    transitionTime={400}
    interval={7000}
    aria-label="Certificados"
  >
    {certificates.map(({ id, ...certificateProps }) => (
      <div key={id}>
        <CertificadoCard {...certificateProps} />
      </div>
    ))}
  </Carousel>
);

CertificadoCarousel.propTypes = {
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      titulo: PropTypes.string.isRequired,
      imagen: PropTypes.string.isRequired,
      pdf: PropTypes.string.isRequired,
      isLoading: PropTypes.bool
    })
  ).isRequired,
};

export default CertificadoCarousel;