import Particle from "../Particle";
import { Image , Container, Row, Col } from "react-bootstrap";
import AnimatedCursor from "react-animated-cursor";
import ReactCompareImage from 'react-compare-image';
import { Helmet } from 'react-helmet';
import { isMobile } from "react-device-detect";
import Typewriter from "typewriter-effect";

import Pav from "../../Assets/photo1.webp";
import Pav2 from "../../Assets/avatar.webp";
import VMP from "../../Assets/vmpanel.webp";
import DL from "../../Assets/Discord_logo.svg";

const botinvite = "https://voiceme.pavloh.com/invite";

function Home() {
  return (
    <>
      {!isMobile && <AnimatedCursor innerSize={8} trailingSpeed={2} outerSize={20} outerAlpha={0} innerScale={0.7} outerScale={2} innerStyle={{boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)', backgroundColor: '#faedcd'}} outerStyle={{boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)', border: '3px solid #fefaf0'}} clickables={['strong', 'input[type="text"]', 'input[type="email"]', 'input[type="number"]', 'input[type="submit"]', 'input[type="image"]', 'label[for]', 'select', 'textarea', 'button', '.link', 'a', '.image-3d']}/>}
      <div className="home-section">
        <Helmet>
          <title>Pavloh | Inicio</title>
          <meta property="og:url" content="https://pavloh.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Pavloh" />
          <meta property="og:description" content="Pavloh" />
          <meta name="twitter:title" content="Pavloh" />
          <meta name="twitter:description" content="Página web de Pavloh ~ @impavloh Especializado en inteligencia artificial y desarrollo web." />
        </Helmet>
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hey{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>
              <h1 className="heading-name">
                Soy<strong className="main-name"> Pavloh</strong>
              </h1>
              <div style={{ paddingLeft: 40, textAlign: "left" }}>
                <Typewriter
                  className="typewriter"
                  options={{
                    strings: [
                      "Software developer",
                      "Content creator",
                      "Editor",
                      "AI/ML dev",
                      "Wikipedia helper",
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10,
                  }}
                />
              </div>
              <div className="voice-me-container">
                <div style={{ textAlign: 'center' }}>
                  <h2 style={{ color: "#000000" }}>
                    ¡Prueba <strong>VoiceMe</strong>!
                  </h2>
                  <p style={{ color: "#000000" }}>
                    Mi bot verificado de Discord texto a voz usando Inteligencia Artificial
                  </p>
                  {!isMobile && (
                    <Image src={VMP} alt="VoiceMe!" width="360" height="256" style={{ maxWidth: "100%", margin: "5px" }}/>
                  )}
                </div>
                <div className="buttonsVMDiv">
                  <a href="/voiceme" rel="noopener noreferrer">
                    <button className="button-secondary">
                      <span>Ir a la web</span>
                    </button>
                  </a>
                  <a href={botinvite} target="_blank" rel="noopener noreferrer">
                    <button className="button-primary">
                      <div className="button-content">
                        <Image alt="Discord Logo" src={DL}/>
                        <span className="button-text">Añadir a Discord</span>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={5} className="axd2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <div className="image-3d" style={{ width: '100%', height: '100%' }}>
                <ReactCompareImage
                  sliderPositionPercentage={0.28}
                  leftImage={Pav}
                  rightImage={Pav2}
                  skeleton={<div style={{ width: '100%', height: '100%' }}>Loading...</div>}
                  className={`img-fluid image-shadow image-rotate3d axd`}
                  handleSize="25"
                  hover={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home;