import SkeletonCard from '../SkeletonCard';
import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';

const RRSSC2 = ({ id, titulo, enlace, imagen, isLoading }) => (
  isLoading ? <SkeletonCard /> : (
    <Col md={4}>
      <a href={enlace} target="_blank" rel="noopener noreferrer" className="card-link">
        <Card className="mb-3 card-hover-zoom2">
          <Card.Img variant="top" src={imagen} alt={titulo} style={{border: '1px solid #ccc', height: '100px', objectFit: 'cover'}} />
          <Card.Body className="card-container3">
            <Card.Title className="card-title2"><strong>{titulo}</strong></Card.Title>
          </Card.Body>
        </Card>
      </a>
    </Col>
  )
);

RRSSC2.propTypes = {
  id: PropTypes.number.isRequired,
  titulo: PropTypes.string.isRequired,
  enlace: PropTypes.string.isRequired,
  imagen: PropTypes.string.isRequired,
  emoji: PropTypes.string,
  isLoading: PropTypes.bool
};

export default RRSSC2;