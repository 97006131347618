import { BiNews } from "react-icons/bi";
import { RiContactsLine } from "react-icons/ri";
import { PiCertificate } from "react-icons/pi";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="text-left">
            Hola, soy <span className="highlight">Pablo</span> (conocido en internet como "<span className="highlight2">Pavloh</span>") tengo 20 años y vivo actualmente en <span className="highlight">Madrid</span>, <span className="highlight">España</span>.
          </p>
          <p className="text-left">
            Estoy compaginando mis estudios con el desarrollo de diversos proyectos, especialmente algunos relacionados con el Deep Learning (o la Inteligencia Artificial), un campo que siempre me ha apasionado.
          </p>
          <br/>
          <div className="links-container">
            <Link className="link-text" to="/proyectos">
              <div className="logo-icons">
                <AiOutlineFundProjectionScreen style={{ marginBottom: "2px", marginRight: "5px" }} />
                Proyectos
              </div>
            </Link>
            <Link className="link-text" to="/certificados">
              <div className="logo-icons">
                <PiCertificate style={{ marginBottom: "2px", marginRight: "5px" }} />
                Certificados
              </div>
            </Link>
            <Link className="link-text" to="/noticias">
              <div className="logo-icons">
                <BiNews style={{ marginBottom: "2px", marginRight: "5px" }} />
                Noticias
              </div>
            </Link>
            <Link className="link-text" to="/conexiones">
              <div className="logo-icons">
                <RiContactsLine style={{ marginBottom: "2px", marginRight: "5px" }} />
                Contacto
              </div>
            </Link>
          </div>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
