import { CgCPlusPlus } from "react-icons/cg";
import { Col, Row } from "react-bootstrap";
import { DiUnitySmall, DiGithubBadge, DiJavascript1, DiNodejs, DiPython, DiGit, DiJava } from "react-icons/di";
import { SiVisualstudiocode, SiLinux, SiWindows, SiTwitter, SiDiscord, SiAdobepremierepro, SiAdobephotoshop, SiCisco, SiCondaforge, SiMicrosoftoffice } from "react-icons/si";

const tools = [
  { icon: SiAdobepremierepro, xs: 5, md: 2 },
  { icon: SiCisco, xs: 5, md: 2 },
  { icon: SiCondaforge, xs: 5, md: 2 },
  { icon: SiMicrosoftoffice, xs: 5, md: 2 },
  { icon: SiAdobephotoshop, xs: 5, md: 2 },

  { icon: SiTwitter, xs: 4, md: 3 },
  { icon: DiGithubBadge, xs: 5, md: 2 },
  { icon: SiDiscord, xs: 5, md: 3 },
  { icon: DiUnitySmall, xs: 5, md: 3 },

  { icon: SiLinux, xs: 5, md: 2 },
  { icon: SiWindows, xs: 5, md: 2 },
  { icon: SiVisualstudiocode, xs: 5, md: 3 },
  
  { icon: DiGit, xs: 5, md: 1 },
  { icon: CgCPlusPlus, xs: 4, md: 1 },
  { icon: DiJavascript1, xs: 4, md: 1 },
  { icon: DiNodejs, xs: 4, md: 1 },
  { icon: DiPython, xs: 4, md: 1 },
  { icon: DiJava, xs: 4, md: 1 },
];

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center" }}>
      {tools.map((tool, index) => (
        <Col key={index} xs={tool.xs} md={tool.md} className="tech-icons">
          <tool.icon />
        </Col>
      ))}
    </Row>
  );
}

export default Toolstack;

/*
Cambiar codigo y hacer el toolstack diferente je
*/