import { Image , Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.webp";
import Toolstack from "./Toolstack";
import { Helmet } from 'react-helmet';
import AnimatedCursor from "react-animated-cursor"
import { isMobile } from "react-device-detect";

function About() {
  return (
    <Container fluid className="about-section">
      {!isMobile && <AnimatedCursor innerSize={8} trailingSpeed={3} outerSize={20} outerAlpha={0} innerScale={0.7} outerScale={2} innerStyle={{ boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)', backgroundColor: '#faedcd'}} outerStyle={{boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)', border: '3px solid #fefaf0'}} clickables={['.blue','input[type="text"]','input[type="email"]','input[type="number"]','input[type="submit"]','input[type="image"]','label[for]','select','textarea','button','.link','a','.tech-icons','.logo-icons']}/>}
      <Helmet>
        <title>Pavloh | Sobre mi</title>
        <meta property="og:title" content="Sobre mi" />
        <meta property="og:description" content="Sobre mi" />
        <meta property="og:url" content="https://www.pavloh.com/info" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Sobre mi" />
        <meta name="twitter:description" content="Sobre mi" />
      </Helmet>
      <Particle />
      <Container >
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={7} style={{ justifyContent: "center", paddingTop: "30px"}}>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}><strong className="blue">Sobre mi </strong></h1>
            <Aboutcard />
          </Col>
          <Col md={5} style={{ paddingTop: "120px", paddingBottom: "50px" }} className="about-img">
          <Image src={laptopImg} alt="about" className="img-fluid"/>
          </Col>
        </Row>
        {!isMobile &&
          <div>
            <h1 className="project-heading">Algunas <strong className="blue">herramientas</strong> que utilizo</h1>
            <Toolstack />
          </div>
        }
      </Container>
    </Container>
  );
}

export default About;
