import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { GrCertificate } from 'react-icons/gr';
import { AiOutlineDownload } from "react-icons/ai";
import { Card, Col, Button, Modal } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import SkeletonCard from '../SkeletonCard';

import 'react-lazy-load-image-component/src/effects/blur.css';

const CertificadoCard = ({ titulo, imagen, pdf, isLoading = false, isFirst = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = useCallback(() => setIsOpen(prev => !prev), []);

  if (isLoading) {
    return <SkeletonCard />;
  }

  return (
    <>
      <Col md={4}>
        <Card className="mb-3 card-hover-zoom" onClick={toggleModal}>
          <LazyLoadImage 
            src={imagen} 
            alt={`Preview of ${titulo}`} 
            visibleByDefault={isFirst} 
            threshold={10} 
            effect="blur" 
            className="bb card-image2 mx-auto d-flex"
          />
        </Card>
      </Col>
      <Modal size="lg" show={isOpen} onHide={toggleModal} centered className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title><GrCertificate /> {titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img alt={`Certificate for ${titulo}`} src={imagen} className="card-image3 mx-auto d-flex"/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>Cerrar</Button>
          <a href={pdf} download={`Pavloh - ${titulo}.pdf`} target="_self">
            <Button variant="primary" className="download-button">
              <AiOutlineDownload className="icon-spacer"/>
              Descargar PDF
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CertificadoCard.propTypes = {
  titulo: PropTypes.string.isRequired,
  imagen: PropTypes.string.isRequired,
  pdf: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isFirst: PropTypes.bool
};

export default memo(CertificadoCard);