import { useCallback, useMemo } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";

const Particle = () => {
    const particlesInit = useCallback(loadSlim, []);
    const particlesConfig = useMemo(() => ({
        particles: {
            number: {
                value: 75,
                density: {
                    enable: true,
                    value_area: 5000
                }
            },
            links: {
                enable: false,
                opacity: 0.03
            },
            size: {
                value: 1
            },
            opacity: {
                anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.05
                }
            }
        },
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push"
                }
            },
            modes: {
                push: {
                    particles_nb: 1
                }
            }
        },
        detectRetina: true
    }), []);

    return <Particles init={particlesInit} id="tsparticles" className="particles-fade" options={particlesConfig} />;
};

export default Particle;